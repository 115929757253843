import {
    PropertyTypes,
} from '../'

import React from 'react';
import dynamic from 'next/dynamic';
import { isMobile } from 'react-device-detect';

export type OnCateogryChanged = (a: any) => void;
export type OnPageChanged = (page, pageSize) => void;
export type OnCardClick = (a: any) => void;
export type onMouseDown = (e: any, d: any, linkpath: any) => void;
export type PropertyListData = {
    total: number,
    page: number,
    items: any,
    sort: any,
}
export type PropertyListProps = { 
    breadcrumb: string[], 
    locale: string,
    t: any,
    data: PropertyListData,
    h1Text: string,
    h2Text: string,
    action: string,
    type: PropertyTypes,
    handleOnCategoryChanged: OnCateogryChanged,
    handlePageChange: OnPageChanged,
    handleCardOnClick: OnCardClick,
    handleOnMouseDown: onMouseDown,
    isMobileFilterOpen: any,
    onMobileSearchOk: any,
    onMobileSearchClose: any,
    seoautocontent: any,
    provinceTable: any,
    actionsTable: any,
    categoryTable: any,
    locationTypeTable: any,
    showListPathIds: any,
    bootsData: any,
    page?: any
    seocontent?: any,
    actionCateLocationType: any
    loading: boolean;
    recommendList: any,
    category: any,
    hasrecommendList: any,
    endList: boolean,
    categoryid: string,
    actionid: string
};

const DynamicPropertyListSectionMobile = dynamic<any>(() => import('./mobile')
  .then(module => module.PropertyListSectionMobile), {
    ssr: false,
  })

const DynamicPropertyListSectionDesktop = dynamic<any>(() => import('./desktop')
  .then(module => module.PropertyListSection), {
    ssr: false,
  })
 
 
export const PropertyListSection = (props: PropertyListProps) => {

    return (

        isMobile ? <DynamicPropertyListSectionMobile {...props} />
        : <DynamicPropertyListSectionDesktop {...props} />
    )
};